import { ChainId } from "@layerzerolabs/lz-sdk";
import { Token } from "@layerzerolabs/x-trader-joe-bridge";

const tokens = [
  // @layerzerolabs/solidity-examples/contracts/token/oft/v2/fee/OFTWithFee.sol
  // remoteOFT (bridged version of the original erc20 on destination chain)
  // For deployment how-to, refer the file "solidity-examples\_deployment\goerli-bsc-testnet.txt"
  new Token(
    ChainId.BSC_TESTNET, // destination chain
    "0xa11e21f7556e735770538B82cBDA7df7f3aE5D23",
    18,
    "AVA"
  ),

  // Other destination chains can be added here
  // @layerzerolabs/solidity-examples/contracts/token/oft/v2/fee/OFTWithFee.sol
  // remoteOFT (bridged version of the original erc20 on destination chain)
  // For deployment how-to, refer the file "solidity-examples\_deployment\goerli-bsc-testnet.txt"
  // new Token(
  //   ChainId.ARBITRUM_GOERLI, // destination chain
  //   "0x???",
  //   18,
  //   "AVA"
  // ),
  /////////////////////////////////////////////

  // Original erc20 token
  new Token(
    ChainId.GOERLI, // source chain
    "0xc02C3E37DeE3579621eAfDA5c33159e7f7FaabF9",
    18,
    "AVA"
  ),
];

const proxy = [
  {
    // @layerzerolabs/solidity-examples/contracts/token/oft/v2/fee/ProxyOFTWithFee.sol
    // localOFT (proxy version of the original erc20 on source chain)
    // For deployment how-to, refer the file "solidity-examples\_deployment\goerli-bsc-testnet.txt"
    chainId: ChainId.GOERLI,
    address: "0x4Ca50498EDEaA5D2960D771e1257bfE162c8DFa6",
  },
];

export const tokens_mainnet = { tokens, proxy };
