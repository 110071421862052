import React from "react";
import ReactDOM from "react-dom/client";
import {
  Bridge,
  bootstrap,
  // themeDark,
  themeLight,
  ThemeProvider,
  createWagmiProvider,
} from "@layerzerolabs/x-trader-joe-bridge";

import { wagmi } from "./config/wagmi";
import { tokens_testnet } from "./config/tokens_testnet";
import { tokens_mainnet } from "./config/tokens_mainnet";
import { ConnectButton, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { isMobile } from "react-device-detect";

import "./styles/reset.css";
import "./styles/fonts.css";
import "@rainbow-me/rainbowkit/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { WagmiConfig } from "wagmi";

// boostrap the bridge
bootstrap(
  process.env.REACT_APP_MAINNET === "true" ? tokens_mainnet : tokens_testnet,
  {
    evm: wagmi.wagmiAdapter,
  }
);

const EthereumAdapterProvider = createWagmiProvider(
  wagmi
) as React.FC<React.PropsWithChildren>;

const App = () => {
  return (
    <Layout>
      <EthereumAdapterProvider>
        <ThemeProvider theme={themeLight}>
          <Bridge />
        </ThemeProvider>
      </EthereumAdapterProvider>
    </Layout>
  );
};

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div
      style={{
        display: isMobile ? "flex-column" : "flex",
        justifyContent: "center",
        background: "#F0F0FF",
        marginTop: isMobile ? "-100px" : "0",
      }}
    >
      {children}
    </div>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const MobileRender: React.FC<React.PropsWithChildren> = () => {
  return (
    <div
      style={{
        display: "flex-column",
        background: "#F0F0FF",
      }}
    >
      <div
        style={{
          margin: "0 0 20px 20px",
        }}
      >
        <a
          href='https://www.avafoundation.org'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='logo.png'
            alt=''
            width='40%'
            style={{ paddingTop: "10px" }}
          />
        </a>
      </div>
      <div
        style={{
          margin: "0 20px 0 20px",
        }}
      >
        <ConnectButton />
      </div>
    </div>
  );
};

const DesktopRender: React.FC<React.PropsWithChildren> = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        background: "#F0F0FF",
      }}
    >
      <div
        style={{
          display: "flex-none",
          justifyItems: "left",
          margin: "30px",
        }}
      >
        <a
          href='https://www.avafoundation.org'
          target='_blank'
          rel='noreferrer'
        >
          <img src='logo.png' alt='' width='40%' />
        </a>
      </div>
      <div
        style={{
          display: "flex-none",
          justifyItems: "right",
          margin: "40px",
        }}
      >
        <ConnectButton />
      </div>
    </div>
  );
};

// don't use strict mode with rainbowkit
// see: https://github.com/rainbow-me/rainbowkit/issues/836
root.render(
  <>
    {/* use your own rainbow kit provider */}
    <WagmiConfig client={wagmi.wagmiClient as any}>
      <RainbowKitProvider chains={wagmi.wagmiChains}>
        {isMobile ? <MobileRender /> : <DesktopRender />}
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </>
);
