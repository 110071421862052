import {
  // connectorsForWallets,
  getDefaultWallets,
} from "@rainbow-me/rainbowkit";
// import {
//   injectedWallet,
//   rainbowWallet,
//   walletConnectWallet,
//   metaMaskWallet,
// } from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createClient } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { mainnet, bsc, goerli, bscTestnet } from "@wagmi/core/chains";

import { createWagmiAdapter } from "@layerzerolabs/x-trader-joe-bridge";

const { chains: wagmiChains, provider } = configureChains(
  process.env.REACT_APP_MAINNET === "true"
    ? [mainnet, bsc]
    : [goerli, bscTestnet],
  [publicProvider()]
);

// const connectors = connectorsForWallets([
//   {
//     groupName: "Recommended",
//     wallets: [
//       injectedWallet({ chains: wagmiChains }),
//       rainbowWallet({ projectId, chains }),
//       walletConnectWallet({
//         projectId: "216ce7109daf9e97702c8c0bf3e22bc9",
//         chains: wagmiChains,
//       }),
//     ],
//   },
// ]);

const { connectors } = getDefaultWallets({
  appName: "",
  // projectId not needed for legacy version
  // projectId: "216ce7109daf9e97702c8c0bf3e22bc9",
  chains: wagmiChains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const wagmiAdapter = createWagmiAdapter(wagmiClient as any);

export const wagmi = {
  wagmiClient,
  wagmiChains,
  wagmiAdapter,
};
